import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleSystem = styled.h1`
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  color: #e81e26;
  margin-left: 10px;
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(176, 190, 197, 0.4);
  border-radius: 8px;
  margin-top: 75px;
  width: 376px;
  height: 167px;
`;

export const TitleCard = styled.h2`
  font-weight: 500;
  text-align: center;
  font-size: 28px;
  line-height: 40px;
  color: #455a64;
`;
