import React, { useEffect, useState } from "react";
import firebase from "firebase";
import axios from "axios";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import uiConfig from "./firebase";
import { Container, Row, Card, TitleCard, TitleSystem } from "./styles";
import imgLogo from "./assets/logo.svg";

const Root: React.FC = () => {
  const [isSignIn, setIsSignIn] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");

  let authObserver: () => void;

  const authStateChanged = async (user: any) => {
    if (user) {
      setToken(await user.getIdToken());
      setIsSignIn(!!user);
    }
  };

  useEffect(() => {
    authObserver = firebase.auth().onAuthStateChanged(authStateChanged);
    return () => {
      authObserver();
    };
  }, []);

  useEffect(() => {
    if (isSignIn) {
      let url = "";

      switch (process.env.PARAM) {
        case "development":
          url = "https://authorizations.dev.cloud.carrefour.com.br";
          break;

        case "qa":
          url = "https://authorizations.qa.cloud.carrefour.com.br";
          break;

        default:
          url = "https://authorizations.cloud.carrefour.com.br";
          break;
      }
      axios
        .post(`${url}/v1/users/authorities/refresh`, null, {
          headers: { "X-Authorization-Firebase": token },
        })
        .then((response) => {
          localStorage.setItem("access", JSON.stringify(response.data));
          window.location.href = "/home";
          localStorage.setItem("token", token);

          var user = firebase.auth().currentUser;
          localStorage.setItem("user", JSON.stringify(user));
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log("error " + error);
        });
    }
  }, [isSignIn]);

  return (
    <Container>
      <Row>
        <img src={imgLogo} alt="logo" height="40px" />
        <TitleSystem>Portal Backoffice. </TitleSystem>
      </Row>
      <Row>
        <Card>
          <TitleCard>Entrar</TitleCard>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </Card>
      </Row>
    </Container>
  );
};

export default Root;
