import firebase from "firebase";

let config;

// TODO: Alterar de "development" para "dev"
if (process.env.PARAM === "development") {
  config = {
    apiKey: "AIzaSyCQvKIuBH7kuQDPdxm4ZZDCkPjQ1TdEDXc",
    authDomain: "br-digitalcomm-dev.firebaseapp.com",
  };
}
if (process.env.PARAM === "qa") {
  config = {
    apiKey: "AIzaSyAJ-srxS7yaq4k_l10oPuzEjv5Zt4P11V8",
    authDomain: "br-digitalcomm-qa.firebaseapp.com",
  };
}
// TODO: Configurar Keys Prods.
if (process.env.PARAM === "prd") {
  config = {
    apiKey: "AIzaSyCK96kYbLMLcqgBYLkvw86Alr6p2hSFXno",
    authDomain: "br-digitalcomm-prod.firebaseapp.com",
  };
}
firebase.initializeApp(config);
const uiConfig = {
  signInFlow: "popup",
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        hd: "carrefour.com",
      },
    },
  ],
};

export default uiConfig;
